$color-green: #5f9ea0;
$color-orange: #ffebcd;
$color-userbg: #dedede;
$color-userlist: #efefef;
$dark-text: #444;

#root {
  margin: auto;
}

body {
  box-sizing: border-box;
  text-align: center;
  position: relative;
  color: $dark-text;
  // padding: 30px;

  .title {
    margin-top: 20px;
    font-size: 30px;
    margin-bottom: 20px;
  }

  .header {
    position: sticky;
    top: 0;
    z-index: 2;
    box-shadow: 0 0 10px grey;

    .nav-bar {
      z-index: 3;
      background-color: blanchedalmond;
      padding: 10px;
      ul {
        a {
          text-decoration: none;
        }
        display: flex;
        flex-direction: row;
        justify-content: center;
        list-style: none;
        margin: 0 10px;
        :hover {
          border-color: green;
        }
        li {
          min-width: 50px;
          border: 1px solid black;
          background-color: cadetblue;
          color: white;
          margin: 0 10px;
          padding: 5px;
          border-radius: 5px;
        }
      }
    }
  }
  .user-container {
    padding: 0 50px;
  }

  .container {
    max-width: 1500px;
    margin: auto;
  }

  .login {
    display: flex;
    justify-content: center;
    flex-direction: column;

    #login_title {
      background-color: $color-orange;
    }

    .input-block {
      margin: 40px auto;
      width: 270px;
      display: flex;
      flex-direction: column;
      div {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        padding-bottom: 10px;

        label {
          display: block;
          width: 90px;
        }
        input {
          display: block;
          width: 180px;
        }
      }
    }

    .login-confirm {
      padding: 10px;
      background-color: $color-green;
      font-size: 16px;
      color: white;
    }
  }

  .push-sender {
    input {
      font-size: 24px;
      margin-bottom: 20px;
    }

    button {
      font-size: 20px;
      padding: 7px 14px;
      width: 200px;
      margin: 0 auto;
    }
  }
}

table {
  border: 2px $color-userbg solid;
  // border-radius: 10px;
  overflow: hidden;
  margin: 0 auto;
  background-color: $color-userbg;
  // padding: 20px;

  thead {
    background-color: $color-userlist;
    tr th {
      padding: 10px 0;
    }
  }

  tbody {
    background-color: $color-userlist;

    input,
    select {
      border: 1px #ccc solid;
    }
  }
}

.user-container {
  .users-title {
  }

  .users_filter-block {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .user-filter {
      width: 300px;
      outline: none;
      padding: 10px;
      border-color: transparent;
      margin: 5px 0;
      border-bottom: 1px solid $dark-text;
      &:focus {
        border-bottom-color: green;

        // margin: 0;
      }
    }
  }

  table {
    border: 2px $color-userbg solid;
    // border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
    background-color: $color-userbg;
    // padding: 20px;

    thead {
      background-color: $color-userlist;
      tr th {
        padding: 10px 0;
      }
    }

    tbody {
      background-color: $color-userlist;

      .user-manager {
        background-color: #FFD700;
      }

      .user-client {
        background-color: #999;
      }

      .user-main {
        background-color: #9d9;
      }

      .item__id {
        font-size: 14px;
        text-align: left;
      }

      .item__name {
        font-weight: 500;
      }

      .itme__select {
        text-align: left;
        align-items: last;
      }

      .item__russian-name {
        text-align: left;
      }

      .item__russian-name input {
        width: 130px;
      }
    }

    .item__id {
      width: 280px;
      // flex: 1;
    }
    .item__name {
      width: 230px;
      text-align: left;
      padding-left: 5px;
    }
    .item__status {
      width: 100px;
    }
    .item__russian-name {
      width: 180px;
    }
    .itme__select {
      width: 180px;
    }
    .item__tokens {
      width: 320px;
      display: flex;
      flex-direction: column;
    }
    .item__delete-button {
      width: 80px;

      button {
        background-color: #ddd;
        border: 1px solid #555;
      }
    }
  }
}

#notification-table {
  .notification-text {
    flex: 1;
  }

  .notification-name {
    flex: 2;
  }
  .notification-time {
    flex: 1;
  }

  .notification-id {
    flex: 2;
  }

  .notification-status {
    flex: 1;
  }

  .notification-token {
    flex: 2;
  }

  .notification-delivered {
    flex: 1;
  }

  .notification-delivered {
    background-color: #8aff05;
  }
  .notification-notDelivered {
    background-color: rgba(255, 0, 0, 0.5);
  }
  .notification-error {
    background-color: rgba(122, 2, 2, 0.788);
  }
  .notification-taked {
    background-color: rgba(255, 255, 2, 0.521);
  }
}

.time-range {
  background-color: "red";
  padding: 20px;
  display: flex;
  justify-content: space-around;
  &__item {
    &-text {
      font-weight: "bold";
    }

    &-input {
      margin-left: 10px;
    }
  }
}

.push-sender {
  padding: 10px;
  display: flex;
  flex-direction: column;
}
